import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  Button,
  // Autocomplete,
  InputAdornment,
  IconButton,
  Link,
  CircularProgress,
  FormControl,
  Divider,
  // createFilterOptions,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import OtpInput from "react-otp-input";
import { useRegister } from "../../../hooks/useRegister";
import { useSnackbar } from "notistack";
import "../../../../src/App.css";
import { City } from "country-state-city";
import countryCodesJson from "../../../common/json/country.json";
import { LuChevronDown } from "react-icons/lu";
import { useAuthContext } from "../../../hooks/useAuthContext";

var cities = City.getCitiesOfCountry("IN");
var cityNames = [];

for (var i = 0; i < cities.length; i++) {
  cityNames.push(cities[i].name);
}

const validationSchema = yup.object().shape({
  typeId: yup.mixed().required("* Type is required"),
  phone: yup
    .string("* Please enter valid number")
    .length(10, "* Please enter valid number")
    .matches(/^[0-9]+$/, "* Please enter valid number")
    .typeError("* Please enter valid number")
    .required("* Whatsapp Number is Required"),
  name: yup.string().trim().required("* Name is required"),
  email: yup
    .string()
    .trim()
    .email("* Invalid email address")
    .required("* Email is required"),
  password: yup
    .string()
    .min(8, "*Password must be at least 8 characters")
    .required("*Password is required"),
  // location: yup.string().required("*Location is required"),
  checkbox: yup
    .boolean()
    .oneOf([true], "*You must accept the terms and conditions"),
});

const SignUp = ({ onToggle, onClose, setIsLoggedIn }) => {
  // const { register, isLoading, error, token } = useRegister();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [types, setTypes] = useState([]);
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [isVerfiy, setIsVerfiy] = useState(null);
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setResendDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [token, setToken] = useState(null);
  const { dispatch } = useAuthContext();

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          setResendDisabled(false);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const handleResendClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/resend-otp`,
        { email: otpFormik.values.email },
        {
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (response.status === 201) {
        setResendDisabled(true);
        setTimer(30);
        startTimer();
        enqueueSnackbar(response.data.message, {
          variant: "success",
        });
      } else {
        console.error("Resend OTP failed:", response.data.message);
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    formik.setFieldValue(name, type === "checkbox" ? checked : value);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      countryCode: countryCodesJson[97],
      phone: "",
      typeId: "",
      // location: "",
      showPassword: false,
      checkbox: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const updatedValues = {
          ...values,
          countryCode: values.countryCode.code,
        };
        setIsLoading(true);
        setError(null);

        const config = {
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": process.env.REACT_APP_API_KEY,
          },
        };

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/register`,
            updatedValues,
            config
          );

          if (response.data) {
            setToken(response.data.data.authentication.accessToken);
            setShowOtpSection(true);
            otpFormik.setValues({ ...otpFormik.values, email: values.email });
            setTimer(30);
            startTimer();
            setResendDisabled(true);
            setIsLoading(false);
            enqueueSnackbar(response.data.message, {
              variant: "success",
            });
          } else {
            setIsLoading(false);
            setError(response.data.message);
          }
        } catch (error) {
          setIsLoading(false);
          setError(
            error?.response?.data?.message || "An unexpected error occurred"
          );
        }
        // console.log(isLoading, error);
        // if (!isLoading && !error) {
        //   setShowOtpSection(true);
        //   otpFormik.setValues({ ...otpFormik.values, email: values.email });
        //   setTimer(30);
        //   startTimer();
        //   setResendDisabled(true);
        // }
      } catch (error) {
        console.error("Unexpected error during registration:", error);
      }
    },
  });

  const otpValidationSchema = yup.object().shape({
    otp: yup
      .string()
      .matches(/^\d{6}$/, "*OTP must be a 6-digit number")
      .required("*OTP is required"),
  });
  const otpFormik = useFormik({
    initialValues: {
      otp: "",
      email: "",
    },
    validationSchema: otpValidationSchema,
    onSubmit: async (values) => {
      setIsVerfiy(true);

      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": process.env.REACT_APP_API_KEY,
        },
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/verify-otp`,
          { otp: values.otp, email: values.email },
          config
        );
        if (response.status === 201) {
          localStorage.setItem("token", token);
          setIsLoggedIn(true);
          dispatch({ type: "LOGIN", payload: response.data });
          onClose();
          navigate("/dashboard");
          setIsVerfiy(false);
          enqueueSnackbar("OTP verification successful!", {
            variant: "success",
          });
        }
      } catch (error) {
        const errorMsg = error.response?.data.message || "An error occurred.";
        setErrorMessage(errorMsg);
        setIsVerfiy(false);
        console.error("Error submitting OTP:", error);
      }
    },
  });

  useEffect(() => {
    const config = {
      headers: {
        "Api-Key": process.env.REACT_APP_API_KEY,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/users/user-types`, config)
      .then((response) => {
        setTypes(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching types:", error);
      });
  }, []);

  return (
    <Box>
      <Typography variant="h3" mb={4} textAlign="center">
        {showOtpSection ? "Verify OTP" : "Sign Up"}
      </Typography>
      {showOtpSection ? (
        <Box>
          {errorMessage && (
            <Typography
              variant="body1"
              sx={{
                backgroundColor: "#EFD9D6",
                color: "primary.main",
                borderRadius: "10px",
                p: 2,
                mb: 2,
              }}
            >
              {errorMessage}
            </Typography>
          )}
          <Box mb={2} className="otp-input">
            <OtpInput
              value={otpFormik.values.otp}
              onChange={(otp) => otpFormik.setFieldValue("otp", otp)}
              numInputs={6}
              renderSeparator={<span style={{ width: "20px" }}></span>}
              renderInput={(props) => <input {...props} />}
              isInputNum={true}
              shouldAutoFocus={true}
              inputType="number"
              inputStyle={{
                border: "1px solid #000",
                background: "rgba(255, 255, 255, 0.10)",
                borderRadius: "8px",
                fontSize: "18px",
                color: "#000",
                fontWeight: "500",
                caretColor: "#000",
              }}
              focusStyle={{
                border: "1px solid #000",
                outline: "none",
              }}
            />
            {otpFormik.touched.otp && otpFormik.errors.otp && (
              <Typography color="error" textAlign={"center"} my={2}>
                {otpFormik.errors.otp}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {timer > 0 ? (
              <Typography
                variant="body1"
                color="inherit"
                my={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                Time Remaining: 00:{timer < 10 ? `0${timer}` : timer}
                <Link
                  component="button"
                  variant="body1"
                  fontWeight="600"
                  onClick={handleResendClick}
                  disabled={isResendDisabled}
                  sx={{
                    color: isResendDisabled ? "gray" : "primary",
                    cursor: isResendDisabled ? "not-allowed" : "pointer",
                    textDecoration: "underline",
                  }}
                >
                  Resend OTP
                </Link>
              </Typography>
            ) : (
              <Link
                component="button"
                variant="body1"
                fontWeight="600"
                onClick={handleResendClick}
                disabled={isResendDisabled}
              >
                Resend OTP
              </Link>
            )}
          </Box>

          <Box textAlign="center" my={3}>
            <Button
              variant="contained"
              onClick={otpFormik.handleSubmit}
              sx={{ px: 8 }}
            >
              {isVerfiy ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : (
                "Verify"
              )}
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              {error && (
                <Typography
                  variant="body1"
                  sx={{
                    backgroundColor: "#EFD9D6",
                    color: "primary.main",
                    borderRadius: "10px",
                    p: 2,
                    mb: 2,
                  }}
                >
                  {error}
                </Typography>
              )}
              <Select
                fullWidth
                name="typeId"
                placeholder="Select Type"
                value={formik.values.typeId}
                onChange={(e) => {
                  formik.setFieldValue("typeId", e.target.value);
                }}
                error={formik.touched.typeId && Boolean(formik.errors.typeId)}
                helperText={formik.touched.typeId && formik.errors.typeId}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Type
                </MenuItem>
                {types.map((type, index) => (
                  <MenuItem key={index} value={type.id}>
                    {type.title}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.typeId && formik.errors.typeId && (
                <Typography variant="caption" color="error" ml={1.5}>
                  {formik.errors.typeId}
                </Typography>
              )}
              <TextField
                fullWidth
                type="text"
                placeholder="Full Name"
                id="name"
                name="name"
                variant="outlined"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{ mt: 1 }}
              />
              {/* <Select
                fullWidth
                name="typeId"
                placeholder="Select Type"
                value={formik.values.typeId}
                onChange={(e) => {
                  formik.setFieldValue("typeId", e.target.value);
                }}
                error={formik.touched.typeId && Boolean(formik.errors.typeId)}
                helperText={formik.touched.typeId && formik.errors.typeId}
              >
                <MenuItem value="" disabled>
                  Select Type
                </MenuItem>
                {types.map((type, index) => (
                  <MenuItem key={index} value={type.id}>
                    {type.title}
                  </MenuItem>
                ))}
              </Select>
              <Box my={1.5}>
                <Autocomplete
                  fullWidth
                  name="location"
                  placeholder="Location"
                  filterOptions={createFilterOptions({
                    limit: 500,
                  })}
                  value={formik.values.location}
                  onChange={(_, value) =>
                    formik.setFieldValue("location", value)
                  }
                  options={cityNames}
                  getOptionLabel={(option) => option.toString()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select location"
                      error={
                        formik.touched.location &&
                        Boolean(formik.errors.location)
                      }
                      helperText={
                        formik.touched.location && formik.errors.location
                      }
                    />
                  )}
                />
              </Box> */}
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mt: 1,
                  position: "relative",
                }}
              >
                <Fragment>
                  <Select
                    labelId="countryCode"
                    id="countryCode"
                    label="countryCode"
                    variant="standard"
                    name="countryCode"
                    IconComponent={(props) => (
                      <LuChevronDown size={22} color="#AE4034" {...props} />
                    )}
                    renderValue={(selected) => selected.dial_code}
                    onChange={formik.handleChange}
                    value={formik.values.countryCode}
                    sx={{
                      position: "absolute",
                      width: "80px !important",
                      "&.MuiInput-root": {
                        border: "none !important",
                        zIndex: 9,
                        fontWeight: 500,
                        color: "#000",
                        height: "100%",
                        "#countryCode": {
                          textAlign: "center !important",
                        },
                      },
                    }}
                    disableUnderline
                  >
                    {countryCodesJson.map((countryCode, index) => (
                      <MenuItem
                        key={index}
                        value={countryCode}
                        sx={{
                          display: "flex",
                          gap: 0.5,
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography variant="subtitle2">
                          {countryCode.dial_code}
                        </Typography>
                        <Typography variant="subtitle2">
                          ({countryCode.name})
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "realtive",
                    }}
                  >
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      sx={{
                        height: "50%",
                        borderColor: "#AE4034",
                        borderWidth: "1px",
                        position: "absolute",
                        zIndex: 9,
                        marginLeft: "180px",
                      }}
                    />
                  </Box>
                </Fragment>
                <TextField
                  sx={{
                    ".MuiInputBase-input": {
                      paddingLeft: "100px !important",
                    },
                  }}
                  fullWidth
                  type="text"
                  placeholder="WhatsApp Number"
                  id="phone"
                  name="phone"
                  variant="outlined"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                />
              </FormControl>
              {formik.touched.phone && Boolean(formik.errors.phone) && (
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", color: "red", ml: 1.5, mt: 0.5 }}
                >
                  {formik.touched.phone && formik.errors.phone}
                </Typography>
              )}
              <Box my={1.5}>
                <TextField
                  fullWidth
                  type="email"
                  name="email"
                  label="Email"
                  {...formik.getFieldProps("email")}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
              <Box my={1.5}>
                <TextField
                  fullWidth
                  type={formik.values.showPassword ? "text" : "password"}
                  name="password"
                  label="Password"
                  {...formik.getFieldProps("password")}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "showPassword",
                              !formik.values.showPassword
                            )
                          }
                        >
                          {formik.values.showPassword ? (
                            <MdVisibility />
                          ) : (
                            <MdVisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <Box my={2}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Checkbox
                  name="checkbox"
                  checked={formik.values.checkbox}
                  onChange={handleChange}
                  sx={{ color: "#000" }}
                />
                <Typography>
                  By clicking here, I state that I have read and understood the
                  <span
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      fontWeight: "600",
                      color: "#AE3034",
                    }}
                    onClick={() => {
                      navigate("/terms-and-conditions");
                    }}
                  >
                    terms and conditions
                  </span>
                  .
                </Typography>
              </Box>
              {formik.touched.checkbox && formik.errors.checkbox && (
                <Typography variant="body2" color="error">
                  {formik.errors.checkbox}
                </Typography>
              )}
            </Box>
            <Box display="flex" justifyContent="center">
              <Button type="submit" variant="contained" sx={{ px: 8 }}>
                {isLoading ? (
                  <CircularProgress size={20} sx={{ color: "white" }} />
                ) : (
                  "Sign Up"
                )}
              </Button>
            </Box>
            <Typography textAlign="center" sx={{ my: 2 }}>
              Already have an account?
              <span
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  fontWeight: "700",
                  color: "#AE4034",
                }}
                onClick={onToggle}
              >
                Log In
              </span>
            </Typography>
          </form>
        </>
      )}
    </Box>
  );
};

export default SignUp;
