import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";
import { useSnackbar } from "notistack";

export const useRegister = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [token, setToken] = useState(null);
  const { dispatch } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const register = async (values) => {
    setIsLoading(true);
    setError(null);

    const config = {
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": process.env.REACT_APP_API_KEY,
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        values,
        config
      );

      if (response.data) {
        setToken(response.data.data.authentication.accessToken);
        dispatch({ type: "LOGIN", payload: response.data });
        setIsLoading(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
        });
      } else {
        setIsLoading(false);
        setError(response.data.message);
        throw new Error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      setError(
        error?.response?.data?.message || "An unexpected error occurred"
      );
      throw error;
    }
  };

  return { register, isLoading, error, token };
};
