import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

const MobileApk = () => {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useAuthContext();

  const access_token = searchParams.get("access_token");

  useEffect(() => {
    if (access_token !== null) {
      window.open("/app-dashboard", "_self");
      dispatch({ type: "LOGIN", payload: access_token });
      localStorage.setItem("token", access_token);
      enqueueSnackbar("You are successfully logged in", {
        variant: "success",
      });
    } else {
      window.open("/404", "_self");
    }
  }, [access_token]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        textAlign: "center",
      }}
    >
      <Typography variant="h2">
        Redirecting to <span style={{ color: "#AE4034" }}>Mistri.Builders</span>
        . Please wait...
      </Typography>
    </Box>
  );
};

export default MobileApk;
