import React, { Fragment, useState, useEffect, forwardRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  // Autocomplete,
  Slide,
  Dialog,
  DialogContent,
  IconButton,
  CircularProgress,
  // createFilterOptions,
  InputLabel,
  FormControl,
  Divider,
} from "@mui/material";
import { LuX, LuImage, LuChevronDown } from "react-icons/lu";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { City } from "country-state-city";
import { enqueueSnackbar } from "notistack";
import { useLogout } from "../../hooks/useLogout";
import { useAuthContext } from "../../hooks/useAuthContext";
import countryCodesJson from "../../common/json/country.json";

var cities = City.getCitiesOfCountry("IN");
var cityNames = [];
const ImageFilesType = ["image/png", "image/jpg", "image/jpeg", "image/heic"];

for (var i = 0; i < cities.length; i++) {
  cityNames.push(cities[i].name);
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditProfile = ({ profileEdit, setProfileEdit }) => {
  const [types, setTypes] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [userData, setUserData] = useState("");
  const { token } = useAuthContext();

  const { logout } = useLogout();

  const formik = useFormik({
    initialValues: {
      name: "",
      profilePic: selectedImage,
      email: "",
      countryCode: countryCodesJson[97],
      phone: "",
      typeId: "",
      // location: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("* Name is required"),
      profilePic: yup.mixed().nullable().optional("* Profile pic is required"),
      email: yup
        .string()
        .email("*Invalid email address")
        .required("*Email is required"),
      phone: yup
        .string("* Please enter valid number")
        .length(10, "* Please enter valid number")
        .matches(/^[0-9]+$/, "* Please enter valid number")
        .typeError("* Please enter valid number")
        .required("* Whatsapp Number is Required"),
      typeId: yup.string().required("*Type is required"),
      // location: yup.string().required("*Location is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      const config = {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Api-Key": process.env.REACT_APP_API_KEY,
        },
      };

      const formData = new FormData();
      formData.append("name", values?.name ?? "");
      if (values?.profilePic) {
        formData.append("profilePic", values?.profilePic ?? "");
      }

      // formData.append("email", values?.email ?? "");
      formData.append("countryCode", values?.countryCode.code ?? "");
      formData.append("phone", values?.phone ?? "");
      formData.append("typeId", values?.typeId ?? "");
      formData.append("location", values?.location ?? "");

      axios
        .put(`${process.env.REACT_APP_API_URL}/users`, formData, config)
        .then((response) => {
          setIsLoading(false);
          if (
            response?.data?.statusCode === 200 ||
            response?.data?.statusCode === 201
          ) {
            setProfileEdit(false);
            enqueueSnackbar(response.data.message, {
              variant: "success",
            });
            setUserData({ ...userData, ...response.data.data });
            localStorage.setItem(
              "user",
              JSON.stringify({ ...userData, ...response.data.data })
            );
            window.location.reload();
            formik.resetForm();
          } else {
            enqueueSnackbar("Something went wrong", {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setError(error.response.data.message);
          setIsLoading(false);
          if (error?.response?.data?.statusCode === 401) {
            logout();
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
          }
        });
    },
  });

  useEffect(() => {
    if (token) {
      const config = {
        headers: {
          "Api-Key": process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .get(`${process.env.REACT_APP_API_URL}/users`, config)
        .then((response) => {
          formik.setValues({
            name: response.data.data.name,
            profilePic: response.data.data.profilePic,
            email: response.data.data.email,
            phone: response.data.data.phone,
            countryCode: countryCodesJson.find(
              (i) => i.code === response.data.data.countryCode
            ),
            typeId: response.data.data.userType.id,
            // location: response.data.data.location,
          });
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
          if (error?.response?.data?.statusCode === 401) {
            logout();
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
          }
        });
    }

    const config = {
      headers: {
        "Api-Key": process.env.REACT_APP_API_KEY,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/users/user-types`, config)
      .then((response) => {
        setTypes(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching types:", error);
      });
  }, [profileEdit]);

  const handleClose = () => {
    setProfileEdit(false);
    formik.resetForm();
  };

  const handleFileChange = (event) => {
    // console.log(event?.target?.files[0]);
    if (!ImageFilesType?.includes(event?.target?.files[0]?.type)) {
      enqueueSnackbar("Please upload a valid profile picture", {
        variant: "warning",
      });
      return false;
    }

    const file = event.target.files[0];

    formik.setFieldValue("profilePic", file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Dialog
      sx={{
        transition: "1.5s ease",
      }}
      open={profileEdit}
      TransitionComponent={Transition}
      onClose={() => setProfileEdit(false)}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent
        sx={{
          backgroundColor: "#FAF9F6 !important",
          color: "#000000 !important",
          borderRadius: "36px",
          backdropFilter: "blur(15px)",
          outline: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={handleClose} sx={{ color: "#000" }}>
            <LuX />
          </IconButton>
        </Box>
        <Typography variant="h3" mb={4} textAlign="center">
          Update Your Profile!
        </Typography>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            {error && (
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: "#EFD9D6",
                  color: "#AE4034",
                  borderRadius: "10px",
                  p: 2,
                  mb: 2,
                }}
              >
                {error}
              </Typography>
            )}
            <Box display="flex" flexDirection="column" alignItems="center">
              <InputLabel htmlFor="profilePic">
                {typeof formik.values.profilePic === "string" ? (
                  <Box
                    component="div"
                    sx={{
                      border: "2px solid #AE4034",
                      borderRadius: "50%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={formik.values.profilePic}
                      alt="Selected Profile"
                      width="100px"
                      height="100px"
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                    <TextField
                      type="file"
                      id="profilePic"
                      onChange={(e) => handleFileChange(e)}
                      inputProps={{ accept: "image/*" }}
                      style={{ display: "none" }}
                    />
                  </Box>
                ) : selectedImage ? (
                  <Box
                    component="div"
                    sx={{
                      border: "2px solid #AE4034",
                      borderRadius: "50%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={selectedImage}
                      alt="Selected Profile"
                      width="100px"
                      height="100px"
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                    <TextField
                      type="file"
                      id="profilePic"
                      onChange={(e) => handleFileChange(e)}
                      inputProps={{ accept: "image/*" }}
                      style={{ display: "none" }}
                    />
                  </Box>
                ) : (
                  <Box
                    component="div"
                    sx={{
                      width: "100px",
                      height: "100px",
                      border: "2px dashed #ae4034",
                      backgroundColor: "#FFD3CD",
                      borderRadius: "50%",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LuImage
                      style={{
                        height: "32px",
                        width: "32px",
                        color: "#AE4034",
                      }}
                    />
                    <TextField
                      type="file"
                      id="profilePic"
                      onChange={(e) => handleFileChange(e)}
                      inputProps={{ accept: "image/*" }}
                      style={{ display: "none" }}
                    />
                  </Box>
                )}
              </InputLabel>
              <Typography my={1} variant="body1" fontWeight="500">
                Upload profile picture
              </Typography>
            </Box>
            {formik.touched.profilePic && formik.errors.profilePic && (
              <Typography textAlign="center" color="error" variant="body2">
                {formik.errors.profilePic}
              </Typography>
            )}
            <Box mt={1.5}>
              <TextField
                fullWidth
                type="text"
                name="name"
                label="Name"
                {...formik.getFieldProps("name")}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Box>
            <Box my={1.5}>
              <TextField
                fullWidth
                type="email"
                name="email"
                label="Email"
                {...formik.getFieldProps("email")}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                disabled
              />
            </Box>
            <Select
              fullWidth
              name="typeId"
              placeholder="Select Type"
              value={formik.values.typeId}
              onChange={(e) => {
                formik.setFieldValue("typeId", e.target.value);
              }}
              error={formik.touched.typeId && Boolean(formik.errors.typeId)}
              helperText={formik.touched.typeId && formik.errors.typeId}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Type
              </MenuItem>
              {types.map((type, index) => (
                <MenuItem key={index} value={type.id}>
                  {type.title}
                </MenuItem>
              ))}
            </Select>
            {/* <Box my={1.5}>
              <Autocomplete
                fullWidth
                name="location"
                placeholder="Location"
                filterOptions={createFilterOptions({
                  limit: 500,
                })}
                value={formik.values.location}
                onChange={(_, value) => formik.setFieldValue("location", value)}
                options={cityNames}
                getOptionLabel={(option) => option.toString()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select location"
                    error={
                      formik.touched.location && Boolean(formik.errors.location)
                    }
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                  />
                )}
              />
            </Box> */}
            <FormControl
              sx={{
                mt: 1.5,
                display: "flex",
                flexDirection: "row",
                position: "relative",
              }}
            >
              <Fragment>
                <Select
                  labelId="countryCode"
                  id="countryCode"
                  label="countryCode"
                  variant="standard"
                  name="countryCode"
                  IconComponent={(props) => (
                    <LuChevronDown size={22} color="#AE4034" {...props} />
                  )}
                  renderValue={(selected) => selected.dial_code}
                  onChange={formik.handleChange}
                  value={formik.values.countryCode}
                  sx={{
                    position: "absolute",
                    width: "80px !important",
                    "&.MuiInput-root": {
                      border: "none !important",
                      zIndex: 9,
                      fontWeight: 500,
                      color: "#000",
                      height: "100%",
                      "#countryCode": {
                        textAlign: "center !important",
                      },
                    },
                  }}
                  disableUnderline
                >
                  {countryCodesJson.map((countryCode, index) => (
                    <MenuItem
                      key={index}
                      value={countryCode}
                      sx={{
                        display: "flex",
                        gap: 0.5,
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography variant="subtitle2">
                        {countryCode.dial_code}
                      </Typography>
                      <Typography variant="subtitle2">
                        ({countryCode.name})
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "realtive",
                  }}
                >
                  <Divider
                    orientation="vertical"
                    variant="fullWidth"
                    sx={{
                      height: "50%",
                      borderColor: "#AE4034",
                      borderWidth: "1px",
                      position: "absolute",
                      zIndex: 9,
                      marginLeft: "180px",
                    }}
                  />
                </Box>
              </Fragment>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#656565",
                  },
                  ".MuiInputBase-input": {
                    paddingLeft: "100px !important",
                  },
                }}
                fullWidth
                type="text"
                placeholder="WhatsApp Number"
                id="phone"
                name="phone"
                variant="outlined"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
              />
            </FormControl>
            {formik.touched.phone && Boolean(formik.errors.phone) && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  color: "red",
                  ml: 1.5,
                  mt: 0.5,
                }}
              >
                {formik.touched.phone && formik.errors.phone}
              </Typography>
            )}
            <Box display="flex" justifyContent="center">
              <LoadingButton
                // fullWidth
                type="submit"
                variant="contained"
                loading={isLoading}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "white" }} />
                }
                sx={{ my: 2, px: 8 }}
              >
                Save
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditProfile;
