import React, { useState, useEffect, forwardRef } from "react";
import {
  Box,
  Button,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Container,
  Divider,
  Dialog,
  DialogContent,
  Slide,
  Avatar,
  Stack,
  Menu,
  MenuItem,
  TextField,
  CircularProgress,
  Link,
  InputAdornment,
  DialogActions,
} from "@mui/material";
import { useNavigate, NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../resources/svgs/Logo1.svg";
// import { ReactComponent as Check } from "../../resources/svgs/Check.svg";
import SignUp from "./Auth/SignUp";
import LogIn from "./Auth/LogIn";
import { useLogout } from "../../hooks/useLogout";
import {
  LuEqual,
  LuLogOut,
  LuX,
  LuChevronDown,
  LuKey,
  LuLayoutDashboard,
} from "react-icons/lu";
import { TbUserEdit } from "react-icons/tb";
import DrwaerLogo from "../../resources/svgs/DrawerLogo.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import OtpInput from "react-otp-input";
import ChangePassword from "./ChangePassword";
import EditProfile from "./EditProfile";
import { FiEye, FiEyeOff } from "react-icons/fi";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const CheckItem = ({ text }) => (
//   <Box
//     sx={{
//       display: "flex",
//       alignItems: "center",
//       color: "white",
//       gap: 1,
//     }}
//   >
//     <Check />
//     <Typography variant="caption">{text}</Typography>
//   </Box>
// );

const Navbar = () => {
  const navigate = useNavigate();
  const { logout } = useLogout();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileEdit, setProfileEdit] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setResendDisabled] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [profilePic, setProfilePic] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("*Invalid email address")
        .required("*Email is required"),
    }),
    onSubmit: async (values) => {
      setError();
      setIsLoading(true);

      const config = {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Api-Key": process.env.REACT_APP_API_KEY,
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/forgot-password`,
          values,
          config
        )
        .then((response) => {
          enqueueSnackbar(response.data.message, {
            variant: "success",
          });
          setError();
          setIsLoading(false);
          setForgotPassword(false);
          formik.resetForm();
          handleOTP();
          otpFormik.setValues({ ...otpFormik.values, email: values.email });
          setTimer(30);
          startTimer();
        })
        .catch((error) => {
          console.log(error);
          setError(error.response.data.message);
          setIsLoading(false);
          if (error?.response?.data?.statusCode === 401) {
            logout();
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
          }
        });
    },
  });

  const otpFormik = useFormik({
    initialValues: {
      otp: "",
      email: "",
    },
    validationSchema: yup.object().shape({
      otp: yup
        .string()
        .matches(/^\d{6}$/, "*OTP must be a 6-digit number")
        .required("*OTP is required"),
    }),
    onSubmit: async (values) => {
      setError();
      setIsLoading(true);

      const config = {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Api-Key": process.env.REACT_APP_API_KEY,
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/verify-reset-password-otp`,
          values,
          config
        )
        .then((response) => {
          enqueueSnackbar(response.data.message, {
            variant: "success",
          });
          setError();
          setIsLoading(false);
          setIsOtp(false);
          otpFormik.resetForm();
          handlePassword();
          passwordFormik.setValues({
            ...passwordFormik.values,
            email: values.email,
          });
        })
        .catch((error) => {
          console.log(error);
          setError(error.response.data.message);
          setIsLoading(false);
          if (error?.response?.data?.statusCode === 401) {
            logout();
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
          }
        });
    },
  });

  const passwordFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confPassword: "",
    },
    validationSchema: yup.object({
      password: yup
        .string("* Enter new password")
        .min(8, "* Password should be of minimum 8 characters length")
        .required("* New password is required"),
      confPassword: yup
        .string("* Enter confirm password")
        .min(8, "* Password should be of minimum 8 characters length")
        .oneOf(
          [yup.ref("password"), null],
          "* Confirm password must be same as password"
        )
        .required("* Confirm password is required"),
    }),
    onSubmit: async (values) => {
      setError();
      setIsLoading(true);

      const config = {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Api-Key": process.env.REACT_APP_API_KEY,
        },
      };

      const body = {
        email: values.email,
        password: values.password,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/reset-password`, body, config)
        .then((response) => {
          enqueueSnackbar(response.data.message, {
            variant: "success",
          });
          setError();
          setIsLoading(false);
          setIsPassword(false);
          passwordFormik.resetForm();
        })
        .catch((error) => {
          console.log(error);
          setError(error.response.data.message);
          setIsLoading(false);
          if (error?.response?.data?.statusCode === 401) {
            logout();
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
          }
        });
    },
  });

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          setResendDisabled(false);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const handleResendClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/resend-otp`,
        { email: otpFormik.values.email, isForgetPasswordOtp: true },
        {
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (response.status === 201) {
        setResendDisabled(true);
        setTimer(30);
        startTimer();
        enqueueSnackbar(response.data.message, {
          variant: "success",
        });
      } else {
        console.error("Resend OTP failed:", response.data.message);
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  const handleDrawerOpen = () => setIsDrawerOpen(true);
  const handleDrawerClose = () => setIsDrawerOpen(false);
  const handleUserMenu = (e) => setAnchorEl(e.currentTarget);
  const handleCloseUserMenu = () => setAnchorEl(null);
  const handleScroll = () => {
    if (window.scrollY > 525) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };
  const handleDashboard = () => {
    navigate("/dashboard");
    setAnchorEl(null);
  };
  const handleProfileEdit = () => {
    setProfileEdit(true);
    setAnchorEl(null);
  };
  const handleChangePassword = () => {
    setChangePassword(true);
    setAnchorEl(null);
  };
  const handleForgotPassword = () => {
    setForgotPassword(true);
    setIsLoginOpen(false);
  };
  const handleOTP = () => setIsOtp(true);
  const handlePassword = () => setIsPassword(true);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleForgotClose = () => {
    setForgotPassword(false);
    formik.resetForm();
  };
  const handleOTPClose = () => {
    setIsOtp(false);
    otpFormik.resetForm();
  };
  const handleNewPassword = () => {
    setIsPassword(false);
    passwordFormik.resetForm();
  };
  const handleLogout = async () => {
    try {
      await logout();
      setIsLoggedIn(false);
      setIsLogout(false);
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  const handleLogin = () => setIsLoginOpen(true);
  const handleSignup = () => setIsSignupOpen(true);
  const handleLoginClose = () => setIsLoginOpen(false);
  const handleSignupClose = () => setIsSignupOpen(false);
  const handleLoginToggle = () => {
    setIsLoginOpen(false);
    setIsSignupOpen(true);
  };
  const handleSignupToggle = () => {
    setIsSignupOpen(false);
    setIsLoginOpen(true);
  };
  const handleLogoutOpen = () => {
    setIsLogout(true);
    setAnchorEl(null);
  };
  const handleLogoutClose = () => setIsLogout(false);
  const token = localStorage.getItem("token");

  window.addEventListener("scroll", handleScroll);
  const pagePath = window.location.pathname;
  const isSpecialPage = ["/about", "/interior", "/", "/contact"].includes(
    pagePath
  );

  const menuItems = [
    { text: "Home", path: "/" },
    { text: "About Us", path: "/about" },
    // { text: "Services", path: "/service" },
    { text: "Interior", path: "/interior" },
    { text: "Contact Us", path: "/contact" },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const config = {
        headers: {
          "Api-Key": process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .get(`${process.env.REACT_APP_API_URL}/users`, config)
        .then((response) => {
          setProfilePic(response.data.data.profilePic);
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
          if (error?.response?.data?.statusCode === 401) {
            logout();
            navigate("/");
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
          }
        });
    }
  }, [profilePic, token]);

  return (
    <Box sx={{ left: 0, top: 0, right: 0, zIndex: 1000, position: "fixed" }}>
      <Box
        sx={{
          background:
            scroll || isSpecialPage ? "#000000" : "rgba(255, 255, 255, 0.30)",
          backdropFilter: "blur(10px)",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ pl: "0 !important", pr: "0 !important" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: { lg: 12, sm: 4, xs: 0 },
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: { md: "none", xs: "flex" },
                gap: 1,
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  maxWidth: { sm: "60px !important", xs: "45px !important" },
                  minWidth: { sm: "60px !important", xs: "45px !important" },
                  width: { sm: "60px !important", xs: "45px !important" },
                  height: "45px",
                  padding: "12px",
                }}
                onClick={handleDrawerOpen}
              >
                <LuEqual style={{ fontSize: "28px", color: "#fff" }} />
              </Button>
              <Box
                sx={{
                  cursor: "pointer",
                  height: { sm: "70px", xs: "60px" },
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <Logo style={{ width: "100%", height: "100%" }} />
              </Box>
            </Box>
            <Box
              sx={{
                display: {
                  md: "block",
                  xs: "none",
                },
                cursor: "pointer",
                height: { lg: "90px", md: "80px" },
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              <Logo style={{ width: "100%", height: "100%" }} />
            </Box>

            <Box sx={{ mr: 2 }}>
              {token ? (
                <>
                  <Stack
                    direction="row"
                    spacing={{ xs: "6px", md: "8px" }}
                    alignItems="center"
                    sx={{
                      cursor: "pointer",
                      display: { xs: "flex", md: "none" },
                    }}
                    onClick={handleUserMenu}
                  >
                    <Avatar
                      src={profilePic}
                      sx={{
                        height: { sm: "45px", xs: "40px" },
                        width: { sm: "45px", xs: "40px" },
                      }}
                    />
                    <Stack>
                      <LuChevronDown
                        height="24px"
                        width="24px"
                        style={{
                          rotate: Boolean(anchorEl) && "180deg",
                          transition: "all 0.3s",
                          color: "#FFF",
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Menu
                    sx={{
                      mt: { xs: "55px", md: "65px" },
                      display: { xs: "flex", md: "none" },
                    }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseUserMenu}
                    autoFocus={false}
                  >
                    <MenuItem
                      onClick={handleDashboard}
                      sx={{
                        gap: 2,
                        justifyContent: "flex-start",
                      }}
                    >
                      <LuLayoutDashboard />
                      <Typography textAlign="center" variant="body1">
                        Dashboard
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleProfileEdit}
                      sx={{
                        gap: 2,
                        justifyContent: "flex-start",
                      }}
                    >
                      <TbUserEdit size={19} />
                      <Typography textAlign="center" variant="body1">
                        Edit Profile
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleChangePassword}
                      sx={{
                        gap: 2,
                        justifyContent: "flex-start",
                      }}
                    >
                      <LuKey />
                      <Typography textAlign="center" variant="body1">
                        Change Password
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleLogoutOpen}
                      sx={{
                        gap: 2,
                        justifyContent: "flex-start",
                      }}
                    >
                      <LuLogOut />
                      <Typography textAlign="center" variant="body1">
                        Logout
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Box sx={{ display: { xs: "flex", md: "none" }, gap: 1 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleLogin}
                    sx={{
                      maxWidth: {
                        sm: "80px !important",
                        xs: "60px !important",
                      },
                      minWidth: {
                        sm: "80px !important",
                        xs: "60px !important",
                      },
                      width: { sm: "80px !important", xs: "60px !important" },
                      height: "45px",
                      padding: "12px",
                    }}
                  >
                    Login
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSignup}
                    sx={{
                      maxWidth: {
                        sm: "80px !important",
                        xs: "60px !important",
                      },
                      minWidth: {
                        sm: "80px !important",
                        xs: "60px !important",
                      },
                      width: { sm: "80px !important", xs: "60px !important" },
                      height: "45px",
                      padding: "12px",
                    }}
                  >
                    SignUp
                  </Button>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: { md: "flex", xs: "none" },
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                {menuItems.map((item, index) => (
                  <Button
                    key={index}
                    component={NavLink}
                    to={item.path}
                    sx={{
                      color: "#FFF",
                      textTransform: "capitalize",
                      fontWeight: "400",
                      px: {
                        lg: "25px",
                        md: "10px",
                      },
                      "&.active": {
                        color: "#AE4034",
                        fontWeight: "600",
                      },
                    }}
                    variant="text"
                  >
                    {item.text}
                  </Button>
                ))}
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: "#E6E6E6",
                  display: "flex",
                  my: "4px",
                }}
              />
              {token ? (
                <>
                  <Stack
                    direction="row"
                    spacing={{ xs: "6px", md: "8px" }}
                    alignItems="center"
                    sx={{
                      cursor: "pointer",
                      display: { xs: "none", md: "flex" },
                    }}
                    onClick={handleUserMenu}
                  >
                    <Avatar
                      src={profilePic}
                      sx={{ height: "45px", width: "45px" }}
                    />
                    <Stack>
                      <LuChevronDown
                        height="24px"
                        width="24px"
                        style={{
                          rotate: Boolean(anchorEl) && "180deg",
                          transition: "all 0.3s",
                          color: "#FFF",
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Menu
                    sx={{
                      mt: { xs: "55px", md: "65px" },
                      display: { xs: "none", md: "flex" },
                    }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseUserMenu}
                    autoFocus={false}
                  >
                    <MenuItem
                      onClick={handleDashboard}
                      sx={{
                        gap: 2,
                        justifyContent: "flex-start",
                      }}
                    >
                      <LuLayoutDashboard />
                      <Typography textAlign="center" variant="body1">
                        Dashboard
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleProfileEdit}
                      sx={{
                        gap: 2,
                        justifyContent: "flex-start",
                      }}
                    >
                      <TbUserEdit size={19} />
                      <Typography textAlign="center" variant="body1">
                        Edit Profile
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleChangePassword}
                      sx={{
                        gap: 2,
                        justifyContent: "flex-start",
                      }}
                    >
                      <LuKey />
                      <Typography textAlign="center" variant="body1">
                        Change Password
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleLogoutOpen}
                      sx={{
                        gap: 2,
                        justifyContent: "flex-start",
                      }}
                    >
                      <LuLogOut />
                      <Typography textAlign="center" variant="body1">
                        Logout
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleLogin}
                    sx={{
                      maxWidth: "80px !important",
                      minWidth: "80px !important",
                      width: "80px !important",
                      height: "45px",
                      padding: "12px",
                    }}
                  >
                    Login
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSignup}
                    sx={{
                      maxWidth: "80px !important",
                      minWidth: "80px !important",
                      width: "80px !important",
                      height: "45px",
                      padding: "12px",
                    }}
                  >
                    SignUp
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>

      <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose}>
        <Box width={250}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", padding: 1 }}>
            <IconButton onClick={handleDrawerClose}>
              <LuX />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center" mb={1}>
            <img src={DrwaerLogo} alt="logo" height="80px" width="160px" />
          </Box>
          <List
            sx={{
              "&.MuiList-root": {
                backgroundColor: "#FFFFFF",
                color: "#343434",
              },
            }}
          >
            {menuItems.map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => {
                  handleDrawerClose();
                  navigate(item.path);
                }}
              >
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      <Dialog
        sx={{
          transition: "1.5s ease",
        }}
        open={isLogout}
        TransitionComponent={Transition}
        onClose={handleLogoutClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          sx={{
            backgroundColor: "#FAF9F6 !important",
            color: "#000000 !important",
            borderRadius: "36px",
            backdropFilter: "blur(15px)",
            outline: "none",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                onClick={handleLogoutClose}
                sx={{ color: "common.white" }}
              >
                <LuX />
              </IconButton>
            </Box>
            <Box textAlign="center">
              <Typography variant="h3">Logout!</Typography>
              <Typography variant="h4" fontWeight="500" mt={2} mb={4}>
                Are you sure, you want to Logout?
              </Typography>
              <DialogActions sx={{ mt: 2 }}>
                <Button
                  fullWidth
                  onClick={handleLogoutClose}
                  variant="outlined"
                  color="inherit"
                >
                  Cancel
                </Button>
                <LoadingButton
                  fullWidth
                  onClick={handleLogout}
                  variant="contained"
                  disableElevation
                  disabled={isLoading}
                  loading={isLoading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  }
                >
                  Logout
                </LoadingButton>
              </DialogActions>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{
          transition: "1.5s ease",
        }}
        open={isLoginOpen}
        TransitionComponent={Transition}
        onClose={handleLoginClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          sx={{
            backgroundColor: "#FAF9F6 !important",
            color: "#000000 !important",
            borderRadius: "36px",
            backdropFilter: "blur(15px)",
            outline: "none",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={handleLoginClose} sx={{ color: "#000" }}>
                <LuX />
              </IconButton>
            </Box>
            <LogIn
              onToggle={handleLoginToggle}
              onClose={handleLoginClose}
              setIsLoggedIn={setIsLoggedIn}
              handleForgotPassword={handleForgotPassword}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{
          transition: "1.5s ease",
        }}
        open={isSignupOpen}
        TransitionComponent={Transition}
        onClose={handleSignupClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          sx={{
            backgroundColor: "#FAF9F6 !important",
            color: "#000000 !important",
            borderRadius: "36px",
            backdropFilter: "blur(15px)",
            outline: "none",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={handleSignupClose} sx={{ color: "#000" }}>
                <LuX />
              </IconButton>
            </Box>
            <SignUp
              onToggle={handleSignupToggle}
              onClose={handleSignupClose}
              setIsLoggedIn={setIsLoggedIn}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{
          transition: "1.5s ease",
        }}
        open={forgotPassword}
        TransitionComponent={Transition}
        onClose={handleForgotClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          sx={{
            backgroundColor: "#FAF9F6 !important",
            color: "#000000 !important",
            borderRadius: "36px",
            backdropFilter: "blur(15px)",
            outline: "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleForgotClose} sx={{ color: "#000" }}>
              <LuX />
            </IconButton>
          </Box>
          <Typography variant="h3" mb={2} textAlign="center">
            Forgot Password
          </Typography>
          <Typography variant="body1" mb={4} textAlign="center">
            Please Provide an Email To Receive an Verification OTP.
          </Typography>
          <Box>
            <form onSubmit={formik.handleSubmit}>
              {error && (
                <Typography
                  variant="body1"
                  sx={{
                    backgroundColor: "#EFD9D6",
                    color: "primary.main",
                    borderRadius: "10px",
                    p: 2,
                    mb: 2,
                  }}
                >
                  {error}
                </Typography>
              )}
              <Box my={1}>
                <TextField
                  fullWidth
                  type="email"
                  name="email"
                  label="Email"
                  {...formik.getFieldProps("email")}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <LoadingButton
                  // fullWidth
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  }
                  sx={{ my: 2, px: 8 }}
                >
                  Send
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{
          transition: "1.5s ease",
        }}
        open={isOtp}
        TransitionComponent={Transition}
        onClose={handleOTPClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          sx={{
            backgroundColor: "#FAF9F6 !important",
            color: "#000000 !important",
            borderRadius: "36px",
            backdropFilter: "blur(15px)",
            outline: "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleOTPClose} sx={{ color: "#000" }}>
              <LuX />
            </IconButton>
          </Box>
          <Typography variant="h3" mb={4} textAlign="center">
            Verify Your Email
          </Typography>
          <Box>
            {error && (
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: "#EFD9D6",
                  color: "primary.main",
                  borderRadius: "10px",
                  p: 2,
                  mb: 2,
                }}
              >
                {error}
              </Typography>
            )}
            <Box mb={2} className="otp-input">
              <OtpInput
                value={otpFormik.values.otp}
                onChange={(otp) => otpFormik.setFieldValue("otp", otp)}
                numInputs={6}
                renderSeparator={<span style={{ width: "20px" }}></span>}
                renderInput={(props) => <input {...props} />}
                isInputNum={true}
                shouldAutoFocus={true}
                inputType="number"
                inputStyle={{
                  border: "1px solid #000",
                  background: "rgba(255, 255, 255, 0.10)",
                  borderRadius: "8px",
                  fontSize: "18px",
                  color: "#000",
                  fontWeight: "500",
                  caretColor: "#000",
                }}
                focusStyle={{
                  border: "1px solid #000",
                  outline: "none",
                }}
              />
              {otpFormik.touched.otp && otpFormik.errors.otp && (
                <Typography color="error" textAlign={"center"} my={2}>
                  {otpFormik.errors.otp}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {timer > 0 ? (
                <Typography
                  variant="body1"
                  color="inherit"
                  my={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={1}
                >
                  Time Remaining: 00:{timer < 10 ? `0${timer}` : timer}
                  <Link
                    component="button"
                    variant="body1"
                    fontWeight="600"
                    onClick={handleResendClick}
                    disabled={isResendDisabled}
                    sx={{
                      color: isResendDisabled ? "gray" : "primary",
                      cursor: isResendDisabled ? "not-allowed" : "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Resend OTP
                  </Link>
                </Typography>
              ) : (
                <Link
                  component="button"
                  variant="body1"
                  fontWeight="600"
                  onClick={handleResendClick}
                  disabled={isResendDisabled}
                >
                  Resend OTP
                </Link>
              )}
            </Box>
            <Box display="flex" justifyContent="center">
              <LoadingButton
                // fullWidth
                onClick={otpFormik.handleSubmit}
                variant="contained"
                loading={isLoading}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "white" }} />
                }
                sx={{ my: 2, px: 8 }}
              >
                Verify
              </LoadingButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{
          transition: "1.5s ease",
        }}
        open={isPassword}
        TransitionComponent={Transition}
        onClose={handleNewPassword}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          sx={{
            backgroundColor: "#FAF9F6 !important",
            color: "#000000 !important",
            borderRadius: "36px",
            backdropFilter: "blur(15px)",
            outline: "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleNewPassword} sx={{ color: "#000" }}>
              <LuX />
            </IconButton>
          </Box>
          <Typography variant="h3" mb={4} textAlign="center">
            Create New Password
          </Typography>
          <Box>
            <form onSubmit={passwordFormik.handleSubmit}>
              {error && (
                <Typography
                  variant="body1"
                  sx={{
                    backgroundColor: "#EFD9D6",
                    color: "primary.main",
                    borderRadius: "10px",
                    p: 2,
                    mb: 2,
                  }}
                >
                  {error}
                </Typography>
              )}
              <Box my={1}>
                <TextField
                  sx={{ mt: 0.5 }}
                  type={showNewPassword ? "text" : "password"}
                  fullWidth
                  variant="outlined"
                  id="password"
                  name="password"
                  label="New Password"
                  value={passwordFormik.values.password}
                  onChange={passwordFormik.handleChange}
                  error={
                    passwordFormik.touched.password &&
                    Boolean(passwordFormik.errors.password)
                  }
                  helperText={
                    passwordFormik.touched.password &&
                    passwordFormik.errors.password
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          tabIndex={-1}
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowNewPassword()}
                        >
                          {showNewPassword ? (
                            <FiEye color="#130F26" />
                          ) : (
                            <FiEyeOff color="#130F26" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box my={1}>
                <TextField
                  sx={{ mt: 0.5 }}
                  type={showNewPassword ? "text" : "password"}
                  fullWidth
                  variant="outlined"
                  id="confPassword"
                  name="confPassword"
                  label="Confirm Password"
                  value={passwordFormik.values.confPassword}
                  onChange={passwordFormik.handleChange}
                  error={
                    passwordFormik.touched.confPassword &&
                    Boolean(passwordFormik.errors.confPassword)
                  }
                  helperText={
                    passwordFormik.touched.confPassword &&
                    passwordFormik.errors.confPassword
                  }
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <LoadingButton
                  // fullWidth
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  }
                  sx={{ my: 2, px: 8 }}
                >
                  Save
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>

      <EditProfile profileEdit={profileEdit} setProfileEdit={setProfileEdit} />

      <ChangePassword
        changePassword={changePassword}
        setChangePassword={setChangePassword}
      />
    </Box>
  );
};

export default Navbar;
