import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Link,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
// import { useLogin } from "../../../hooks/useLogin";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useAuthContext } from "../../../hooks/useAuthContext";

const LogIn = ({ onToggle, onClose, setIsLoggedIn, handleForgotPassword }) => {
  const navigate = useNavigate();
  // const { login, isLoading, error, token, isOpenOtpScreen } = useLogin();
  const [showOtp, setShowOtp] = useState(false);
  const [isVerfiy, setIsVerfiy] = useState(null);
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setResendDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState();
  const [error, setError] = useState();

  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("*Invalid email address")
      .required("*Email is required"),
    password: yup
      .string()
      .min(8, "*Password must be at least 8 characters")
      .required("*Password is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      // await login(values);
      setIsLoading(true);

      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": process.env.REACT_APP_API_KEY,
        },
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/login`, values, config)
        .then((response) => {
          if (
            response.data.message ===
            "Your email address has not been verified yet. Please check your mailbox for the verification mail"
          ) {
            enqueueSnackbar(response.data.message, {
              variant: "success",
            });
            setIsLoading(false);
            setTimer(30);
            setShowOtp(true);
            otpFormik.setValues({
              ...otpFormik.values,
              email: values.email,
            });

            setToken(response.data.data.authentication.accessToken);
            startTimer();
          } else {
            localStorage.setItem(
              "token",
              response.data.data.authentication.accessToken
            );
            dispatch({
              type: "LOGIN",
              payload: response.data.data.authentication.accessToken,
            });
            navigate("/dashboard");
            setIsLoading(false);
            enqueueSnackbar(response.data.message, {
              variant: "success",
            });
            setIsLoggedIn(true);
            onClose();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setError(error?.response?.data?.message);
        });
    },
  });

  const otpValidationSchema = yup.object().shape({
    otp: yup
      .string()
      .matches(/^\d{6}$/, "*OTP must be a 6-digit number")
      .required("*OTP is required"),
  });
  const otpFormik = useFormik({
    initialValues: {
      otp: "",
      email: "",
    },
    validationSchema: otpValidationSchema,
    onSubmit: async (values) => {
      setIsVerfiy(true);

      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": process.env.REACT_APP_API_KEY,
        },
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/verify-otp`,
          { otp: values.otp, email: values.email },
          config
        );
        if (response.status === 201) {
          localStorage.setItem("token", token);
          setIsLoggedIn(true);
          onClose();
          dispatch({
            type: "LOGIN",
            payload: response.data.data.authentication.accessToken,
          });
          navigate("/dashboard");
          setIsVerfiy(false);
          enqueueSnackbar("OTP verification successful!", {
            variant: "success",
          });
        }
      } catch (error) {
        const errorMsg = error.response?.data.message || "An error occurred.";
        setErrorMessage(errorMsg);
        setIsVerfiy(false);
        console.error("Error submitting OTP:", error);
      }
    },
  });

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          setResendDisabled(false);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const handleResendClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/resend-otp`,
        { email: otpFormik.values.email },
        {
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (response.status === 201) {
        setResendDisabled(true);
        setTimer(30);
        startTimer();
        enqueueSnackbar(response.data.message, {
          variant: "success",
        });
      } else {
        console.error("Resend OTP failed:", response.data.message);
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  return (
    <>
      <Typography variant="h3" mb={4} textAlign="center">
        {showOtp ? "Verify OTP" : "Log In"}
      </Typography>
      {showOtp ? (
        <Box>
          {errorMessage && (
            <Typography
              variant="body1"
              sx={{
                backgroundColor: "#EFD9D6",
                color: "primary.main",
                borderRadius: "10px",
                p: 2,
                mb: 2,
              }}
            >
              {errorMessage}
            </Typography>
          )}
          <Box mb={2} className="otp-input">
            <OtpInput
              value={otpFormik.values.otp}
              onChange={(otp) => otpFormik.setFieldValue("otp", otp)}
              numInputs={6}
              renderSeparator={<span style={{ width: "20px" }}></span>}
              renderInput={(props) => <input {...props} />}
              isInputNum={true}
              shouldAutoFocus={true}
              inputType="number"
              inputStyle={{
                border: "1px solid #000",
                background: "rgba(255, 255, 255, 0.10)",
                borderRadius: "8px",
                fontSize: "18px",
                color: "#000",
                fontWeight: "500",
                caretColor: "#000",
              }}
              focusStyle={{
                border: "1px solid #000",
                outline: "none",
              }}
            />
            {otpFormik.touched.otp && otpFormik.errors.otp && (
              <Typography color="error" textAlign={"center"} my={2}>
                {otpFormik.errors.otp}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {timer > 0 ? (
              <Typography
                variant="body1"
                color="inherit"
                my={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                Time Remaining: 00:{timer < 10 ? `0${timer}` : timer}
                <Link
                  component="button"
                  variant="body1"
                  fontWeight="600"
                  onClick={handleResendClick}
                  disabled={isResendDisabled}
                  sx={{
                    color: isResendDisabled ? "gray" : "primary",
                    cursor: isResendDisabled ? "not-allowed" : "pointer",
                    textDecoration: "underline",
                  }}
                >
                  Resend OTP
                </Link>
              </Typography>
            ) : (
              <Link
                component="button"
                variant="body1"
                fontWeight="600"
                onClick={handleResendClick}
                disabled={isResendDisabled}
              >
                Resend OTP
              </Link>
            )}
          </Box>
          <Box textAlign="center" my={3}>
            <Button
              variant="contained"
              onClick={otpFormik.handleSubmit}
              sx={{ px: 8 }}
            >
              {isVerfiy ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : (
                "Verify"
              )}
            </Button>
          </Box>
        </Box>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          {error && (
            <Typography
              variant="body1"
              sx={{
                backgroundColor: "#EFD9D6",
                color: "primary.main",
                borderRadius: "10px",
                p: 2,
                mb: 2,
              }}
            >
              {error}
            </Typography>
          )}
          <Box my={1}>
            <TextField
              fullWidth
              type="email"
              name="email"
              label="Email"
              {...formik.getFieldProps("email")}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Box>
          <Box my={2}>
            <TextField
              fullWidth
              type={formik.values.showPassword ? "text" : "password"}
              name="password"
              label="Password"
              {...formik.getFieldProps("password")}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "showPassword",
                          !formik.values.showPassword
                        )
                      }
                    >
                      {formik.values.showPassword ? (
                        <MdVisibility />
                      ) : (
                        <MdVisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Typography
            textAlign="end"
            sx={{
              my: 2,
              color: "#AE4034",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={handleForgotPassword}
          >
            forgot password ?
          </Typography>
          <Box display={"flex"} justifyContent={"center"}>
            <Button type="submit" variant="contained" sx={{ px: 8, my: 2 }}>
              {isLoading ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : (
                "Log In"
              )}
            </Button>
          </Box>

          <Typography textAlign="center" sx={{ my: 2 }}>
            Don't have an account?
            <span
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                fontWeight: "700",
                color: "#AE4034",
              }}
              onClick={onToggle}
            >
              Sign Up
            </span>
          </Typography>
        </form>
      )}
    </>
  );
};

export default LogIn;
