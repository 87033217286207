import React, { useState, useEffect, forwardRef } from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Slide,
} from "@mui/material";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";
// import AOS from "aos";
// import "aos/dist/aos.css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import P1 from "../../resources/images/projects/P1.jpg";
import P12 from "../../resources/images/projects/P1(2).jpg";
import P13 from "../../resources/images/projects/P1(3).jpg";
import P14 from "../../resources/images/projects/P1(4).jpg";
import P15 from "../../resources/images/projects/P1(5).jpg";
import P2 from "../../resources/images/projects/P2.jpg";
import P22 from "../../resources/images/projects/P2(2).jpg";
import P23 from "../../resources/images/projects/P2(3).jpg";
import P24 from "../../resources/images/projects/P2(4).jpg";
import P25 from "../../resources/images/projects/P2(5).jpg";
import P26 from "../../resources/images/projects/P2(6).jpg";
import P3 from "../../resources/images/projects/P3.jpg";
import P32 from "../../resources/images/projects/P3(2).jpg";
import P33 from "../../resources/images/projects/P3(3).jpg";
import P34 from "../../resources/images/projects/P3(4).jpg";
import P4 from "../../resources/images/projects/P4.jpg";
import P42 from "../../resources/images/projects/P4(2).jpg";
import P43 from "../../resources/images/projects/P4(3).jpg";
import P44 from "../../resources/images/projects/P4(4).jpg";
import P45 from "../../resources/images/projects/P4(5).jpg";
import P46 from "../../resources/images/projects/P4(6).jpg";
import P47 from "../../resources/images/projects/P4(7).jpg";
import P48 from "../../resources/images/projects/P4(8).jpg";
import P5 from "../../resources/images/projects/P5.jpg";
import P52 from "../../resources/images/projects/P5(2).jpg";
import P53 from "../../resources/images/projects/P5(3).jpg";
import P54 from "../../resources/images/projects/P5(4).jpg";
import P55 from "../../resources/images/projects/P5(5).jpg";
import P56 from "../../resources/images/projects/P5(6).jpg";
import P57 from "../../resources/images/projects/P5(7).jpg";
import P6 from "../../resources/images/projects/P6.jpg";
import P62 from "../../resources/images/projects/P6(2).jpg";
import P63 from "../../resources/images/projects/P6(3).jpg";
import P64 from "../../resources/images/projects/P6(4).jpg";
import P65 from "../../resources/images/projects/P6(5).jpg";
import P66 from "../../resources/images/projects/P6(6).jpg";
import P67 from "../../resources/images/projects/P6(7).jpg";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Design = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGridItem, setSelectedGridItem] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleGridItemClick = (item, images) => {
    setSelectedGridItem(item);
    setSelectedImages(images);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedGridItem(null);
    setSelectedImages([]);
  };

  // useEffect(() => {
  //   AOS.init();
  // }, []);

  const renderGridItem = (imgSrc, location, title, images, index) => (
    <Grid
      item
      xs={12}
      sm={6}
      key={index}
      onClick={() => handleGridItemClick(title, images)}
      sx={{ cursor: "pointer" }}
      // data-aos="fade-up"
    >
      <Box sx={{ height: { md: "400px", xs: "300px" } }}>
        <img
          src={imgSrc}
          alt={title}
          style={{
            background: "#E6E6E6",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "30px",
          }}
          loading="lazy"
        />
      </Box>

      <Typography variant="h6" color="#AE4034" mt={1}>
        {location}
      </Typography>
      <Typography variant="h4">{title}</Typography>
      {index < 4 ? (
        <Divider
          sx={{
            my: 2,
            mr: { sm: 6, xs: 0 },
            borderBottomWidth: 2,
          }}
        />
      ) : index === 4 ? (
        <Divider
          sx={{
            display: { sm: "none", xs: "block" },
            my: 2,
            mr: { sm: 6, xs: 0 },
            borderBottomWidth: 2,
          }}
        />
      ) : null}
    </Grid>
  );

  return (
    <Container
      maxWidth="xl"
      sx={{ pl: "0 !important", pr: "0 !important", my: 10 }}
    >
      <Box sx={{ px: { lg: 12, md: 8, sm: 4, xs: 2 } }}>
        <Grid container spacing={4}>
          {renderGridItem(
            P1,
            "DLF Gurgaon, Haryana",
            "White Room",
            [P12, P13, P14, P15],
            0
          )}
          {renderGridItem(
            P2,
            "Alaknanda, New Delhi",
            "Brown Egg",
            [P22, P23, P24, P25, P26],
            1
          )}
          {renderGridItem(
            P3,
            "Gurgaon, Haryana",
            "Hope Land",
            [P32, P33, P34],
            2
          )}
          {renderGridItem(
            P4,
            "Paschim Vihar, New Delhi",
            "Pie in the Sky",
            [P42, P43, P44, P45, P46, P47, P48],
            3
          )}
          {renderGridItem(
            P5,
            "Yamuna Nagar, Haryana",
            "Taurus in Mars",
            [P52, P53, P54, P55, P56, P57],
            4
          )}
          {renderGridItem(
            P6,
            "Defence Colony, New Delhi",
            "Golden Nectar",
            [P62, P63, P64, P65, P66, P67],
            5
          )}
        </Grid>
      </Box>
      <Dialog
        fullWidth
        TransitionComponent={Transition}
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{
          transition: "1.5s ease",
        }}
      >
        <DialogTitle>
          <Typography variant="h5">{selectedGridItem}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            borderRadius: "36px",
            outline: "none",
            "&.MuiDialogContent-root": {
              padding: "0px 20px !important",
            },
          }}
        >
          <Swiper
            style={{
              marginBottom: "10px",
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
              height: "400px",
              width: "100%",
            }}
            spaceBetween={10}
            navigation={true}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[FreeMode, Navigation, Thumbs, Autoplay]}
            className="mySwiper2"
          >
            {selectedImages.map((img, index) => (
              <SwiperSlide key={index}>
                <img
                  src={img}
                  alt="img"
                  style={{
                    background: "#E6E6E6",
                    objectFit: "cover",
                    height: "100%",
                    width: "100%",
                    borderRadius: "16px",
                  }}
                  loading="lazy"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper1"
            style={{
              height: "100px",
              width: "100%",
            }}
          >
            {selectedImages.map((img, index) => (
              <SwiperSlide key={index}>
                <img
                  src={img}
                  alt="img"
                  style={{
                    background: "#E6E6E6",
                    objectFit: "cover",
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  loading="lazy"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </DialogContent>
        <DialogActions
          sx={{
            "&.MuiDialogActions-root": {
              padding: "10px 20px 12px 20px !important",
            },
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={handleCloseDialog}
            sx={{ px: 6 }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Design;
