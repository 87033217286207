import React, { Fragment, useState, forwardRef, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  Autocomplete,
  DialogActions,
  Grid,
  DialogContentText,
  FormControl,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { LuX, LuCheckCircle, LuChevronDown } from "react-icons/lu";
import { ReactComponent as Schedule } from "../../resources/svgs/Schedule.svg";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useAuthContext } from "../../hooks/useAuthContext";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { CountryStateDistrict } from "country_state_district";
import dayjs from "dayjs";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useLogout } from "../../hooks/useLogout";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import axios from "axios";
// import { useSnackbar } from "notistack";
// import { CountryStateDistrict } from "country_state_district";
import countryCodesJson from "../../common/json/country.json";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
  name: yup.string().required("*Name is required"),
  email: yup
    .string()
    .email("*Invalid email address")
    .required("*Email is required"),
  phone: yup
    .string("* Please enter valid number")
    .length(10, "* Please enter valid number")
    .matches(/^[0-9]+$/, "* Please enter valid number")
    .typeError("* Please enter valid number")
    .required("* Whatsapp Number is Required"),
  state: yup.mixed().required("*State is required"),
  district: yup.mixed().required("*District is required"),
  city: yup.string().required("*City is required"),
  houseNumber: yup.string().required("*House No is required"),
  locality: yup.string().required("*Locality is required"),
  pincode: yup
    .string()
    .required("*Pincode is required")
    .matches(/^\d{6}$/, "*Invalid Pincode"),
  date: yup.date().required("*Date is required"),
  // time: yup.date().required("*Time is required"),
});

const ScheduleButton = () => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useLogout();
  const [states, setStates] = useState();
  const [districts, setDistricts] = useState();
  const { token } = useAuthContext();

  const [openDialog, setOpenDialog] = useState(false);
  const [apiResponseMessage, setApiResponseMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      countryCode: countryCodesJson[97],
      state: "",
      district: "",
      city: "",
      houseNumber: "",
      locality: "",
      pincode: "",
      date: null,
      // time: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      setError();
      setIsLoading(true);

      const config = {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Api-Key": process.env.REACT_APP_API_KEY,
        },
      };

      const body = {
        ...values,
        date: dayjs(values.date).format("YYYY-MM-DD"),
        name: values.name,
        email: values.email,
        phone: values.phone,
        countryCode: values.countryCode.code,
        state: values.state.name,
        district: values.district.name,
        city: values.city,
        houseNumber: values.houseNumber,
        locality: values.locality,
        pincode: values.pincode,
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/contact/request-inquiry`,
          body,
          config
        )
        .then((response) => {
          setIsOpenDialog(false);
          const successMessage =
            response.data.message || "Form submitted successfully";
          setApiResponseMessage(successMessage);
          setOpenDialog(true);
          setError();
          setIsLoading(false);
          formik.resetForm();
        })
        .catch((error) => {
          console.log(error);
          setError(error.response.data.message);
          setIsLoading(false);
          if (error?.response?.data?.statusCode === 401) {
            logout();
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
          }
        });
    },
  });

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
    formik.resetForm();
  };

  const handleClose = () => {
    setOpenDialog(false);
    setApiResponseMessage("");
  };

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/contact/get-states`,
          {
            headers: {
              "Api-Key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        setStates(response.data.data);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, []);

  useEffect(() => {
    if (token) {
      const config = {
        headers: {
          "Api-Key": process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .get(`${process.env.REACT_APP_API_URL}/users`, config)
        .then((response) => {
          formik.setValues({
            name: response.data.data.name,
            email: response.data.data.email,
            countryCode: countryCodesJson.find(
              (i) => i.code === response.data.data.countryCode
            ),
            phone: response.data.data.phone,
          });
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
          if (error?.response?.data?.statusCode === 401) {
            logout();
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
          }
        });
    }
  }, [isOpenDialog]);

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/contact/get-districts?stateId=${formik.values.state.id}`,
          {
            headers: {
              "Api-Key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        setDistricts(response.data.data);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    };

    if (formik.values.state) {
      fetchDistricts();
    }
  }, [formik.values.state]);

  return (
    <Box position="fixed" bottom={20} right={20} zIndex={10}>
      <Button
        onClick={handleOpenDialog}
        sx={{
          display: "flex",
          maxWidth: "120px",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "#AE4034",
          color: "#FFF",
          px: 0,
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
          "&:hover": {
            background: "#343434",
          },
        }}
      >
        <Schedule />
        <Typography variant="caption" marginTop={0}>
          Schedule free consultation
        </Typography>
      </Button>

      <Dialog
        sx={{
          transition: "1.5s ease",
        }}
        open={isOpenDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogContent
          sx={{
            backgroundColor: "#FAF9F6 !important",
            color: "#000000 !important",
            borderRadius: "36px",
            backdropFilter: "blur(15px)",
            outline: "none",
            padding: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleCloseDialog} sx={{ color: "#000" }}>
              <LuX />
            </IconButton>
          </Box>
          <Typography variant="h3" mb={4} textAlign="center">
            Schedule Free Consultation
          </Typography>
          <Box>
            <form onSubmit={formik.handleSubmit}>
              {error && (
                <Typography
                  variant="body1"
                  sx={{
                    backgroundColor: "#EFD9D6",
                    color: "primary.main",
                    borderRadius: "10px",
                    p: 2,
                    mb: 2,
                  }}
                >
                  {error}
                </Typography>
              )}
              <Grid container spacing={2} sx={{ px: 1, mb: 2 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    name="name"
                    label="Name"
                    {...formik.getFieldProps("name")}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="email"
                    name="email"
                    label="Email"
                    {...formik.getFieldProps("email")}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                    }}
                  >
                    <Fragment>
                      <Select
                        labelId="countryCode"
                        id="countryCode"
                        label="countryCode"
                        variant="standard"
                        name="countryCode"
                        IconComponent={(props) => (
                          <LuChevronDown size={22} color="#AE4034" {...props} />
                        )}
                        renderValue={(selected) => selected.dial_code}
                        onChange={formik.handleChange}
                        value={formik.values.countryCode}
                        sx={{
                          position: "absolute",
                          width: "80px !important",
                          "&.MuiInput-root": {
                            border: "none !important",
                            zIndex: 9,
                            fontWeight: 500,
                            color: "#000",
                            height: "100%",
                            display: "flex !important",
                            justifyContent: "center !important",
                            alignItems: "center !important",
                            "#countryCode": {
                              textAlign: "center !important",
                            },
                          },
                        }}
                        disableUnderline
                      >
                        {countryCodesJson.map((countryCode, index) => (
                          <MenuItem
                            key={index}
                            value={countryCode}
                            sx={{
                              display: "flex",
                              gap: 0.5,
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography variant="subtitle2">
                              {countryCode.dial_code}
                            </Typography>
                            <Typography variant="subtitle2">
                              ({countryCode.name})
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "realtive",
                        }}
                      >
                        <Divider
                          orientation="vertical"
                          variant="fullWidth"
                          sx={{
                            height: "50%",
                            borderColor: "#AE4034",
                            borderWidth: "1px",
                            position: "absolute",
                            zIndex: 9,
                            marginLeft: "180px",
                          }}
                        />
                      </Box>
                    </Fragment>
                    <TextField
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#656565",
                        },
                        ".MuiInputBase-input": {
                          paddingLeft: "100px !important",
                        },
                      }}
                      fullWidth
                      type="text"
                      placeholder="WhatsApp Number"
                      id="phone"
                      name="phone"
                      variant="outlined"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                    />
                  </FormControl>
                  {formik.touched.phone && Boolean(formik.errors.phone) && (
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: "red",
                        ml: 1.5,
                        mt: 0.5,
                      }}
                    >
                      {formik.touched.phone && formik.errors.phone}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    name="state"
                    value={formik.values.state}
                    onChange={async (_, value) => {
                      formik.setFieldValue("state", value);
                      formik.setFieldValue("district", "");
                    }}
                    options={states ? states : []}
                    noOptionsText="Currently we are not available in any state"
                    getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select State"
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={formik.touched.state && formik.errors.state}
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    name="district"
                    value={formik.values.district}
                    onChange={async (_, value) => {
                      formik.setFieldValue("district", value);
                    }}
                    options={districts ? districts : []}
                    getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select District"
                        error={
                          formik.touched.district &&
                          Boolean(formik.errors.district)
                        }
                        helperText={
                          formik.touched.district && formik.errors.district
                        }
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    name="city"
                    label="City"
                    {...formik.getFieldProps("city")}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    name="locality"
                    label="Locality"
                    {...formik.getFieldProps("locality")}
                    error={
                      formik.touched.locality && Boolean(formik.errors.locality)
                    }
                    helperText={
                      formik.touched.locality && formik.errors.locality
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    name="houseNumber"
                    label="House No"
                    {...formik.getFieldProps("houseNumber")}
                    error={
                      formik.touched.houseNumber &&
                      Boolean(formik.errors.houseNumber)
                    }
                    helperText={
                      formik.touched.houseNumber && formik.errors.houseNumber
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    type="tel"
                    name="pincode"
                    label="Pincode"
                    {...formik.getFieldProps("pincode")}
                    inputProps={{ maxLength: 6 }}
                    onWheel={(e) => {
                      e.target.blur();
                      setTimeout(() => {
                        e.target.focus();
                      }, 0);
                    }}
                    error={
                      formik.touched.pincode && Boolean(formik.errors.pincode)
                    }
                    helperText={formik.touched.pincode && formik.errors.pincode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      disablePast
                      sx={{
                        width: "100%",
                      }}
                      format="dd MMM yyyy"
                      slotProps={{
                        textField: {
                          error:
                            formik.touched.date && Boolean(formik.errors.date),
                          helperText: formik.touched.date && formik.errors.date,
                          label: "Date",
                        },
                      }}
                      {...formik.getFieldProps("date")}
                      onChange={(date) => formik.setFieldValue("date", date)}
                      shouldDisableDate={(date) => {
                        const day = date.getDay();
                        return !(day === 4 || day === 5 || day === 6);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              {/* <Box mb={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["MobileTimePicker"]}>
                    <MobileTimePicker
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          placeholder: "Time",
                          error:
                            formik.touched.time && Boolean(formik.errors.time),
                          helperText: formik.touched.time && formik.errors.time,
                        },
                      }}
                      onChange={(time) => {
                        formik.setFieldValue("time", time);
                      }}
                      ampmInClock
                      defaultValue={dayjs(new Date(0, 0, 0, 14))}
                      minTime={dayjs(new Date(0, 0, 0, 14))}
                      maxTime={dayjs(new Date(0, 0, 0, 18))}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box> */}

              {/* <Box
                width="100%"
                sx={{
                  display: "flex",
                  flexDirection: {
                    md: "row",
                    xs: "column",
                  },
                }}
                gap={2}
                my={2}
              >
                <Button
                  variant="outlined"
                  onClick={handleCloseDialog}
                  sx={{
                    width: {
                      md: "50%",
                      xs: "100%",
                    },
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  // fullWidth
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  }
                  sx={{
                    py: 1,
                    width: {
                      md: "50%",
                      xs: "100%",
                    },
                  }}
                >
                  Send
                </LoadingButton>
              </Box> */}
              <DialogActions sx={{ mt: 2 }}>
                <Button
                  fullWidth
                  onClick={handleCloseDialog}
                  variant="outlined"
                  color="inherit"
                >
                  Cancel
                </Button>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  disableElevation
                  disabled={isLoading}
                  loading={isLoading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  }
                >
                  Send
                </LoadingButton>
              </DialogActions>
            </form>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{
          transition: "1.5s ease",
        }}
        TransitionComponent={Transition}
        open={openDialog}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogContent
          sx={{
            backgroundColor: "#ffffff",
            color: "#333333",
            borderRadius: "20px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            padding: "30px",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleClose} sx={{ color: "#000" }}>
              <LuX />
            </IconButton>
          </Box>
          <LuCheckCircle
            style={{ fontSize: "4rem", color: "#ae4034", marginBottom: "20px" }}
          />
          <Typography
            variant="h3"
            id="dialog-title"
            style={{ marginBottom: "20px" }}
          >
            Thank You for Requesting a Free Consultation!
          </Typography>
          <DialogContentText
            variant="h6"
            style={{ marginBottom: "30px", fontWeight: "500" }}
          >
            We'll be in touch soon to discuss how we can assist you further.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ScheduleButton;
