import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";
import { useSnackbar } from "notistack";

export const useLogout = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const logout = async () => {
    setIsLoading(true);
    setError(null);

    const config = {
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {
          deviceId: "b42",
        },
        config
      )
      .then((response) => {
        if (response.data) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");

          // update the auth context
          dispatch({ type: "LOGOUT" });

          // update loading state
          setIsLoading(false);

          enqueueSnackbar(response.data.message, {
            variant: "success",
          });
        } else {
          setIsLoading(false);
          setError(response.data.message);
        }
      })
      .catch((error) => {
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        // update the auth context
        dispatch({ type: "LOGOUT" });

        setIsLoading(false);
        setError(error?.response?.data?.message);
      });
  };

  return { logout, isLoading, error };
};
