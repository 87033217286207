import React from "react";
import { Box, Container, Grid, Link, Typography, Stack } from "@mui/material";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";
// import Logo from "../../resources/svgs/Logo.svg";
// import AOS from "aos";
import "aos/dist/aos.css";
import { HashLink } from "react-router-hash-link";
import BadgeiOS from "../../resources/images/IBadge.png";
import BadgeAndroid from "../../resources/images/ABadge.png";
import MBLogo from "../../resources/images/MBLogo.png";

const socialMediaLinks = [
  {
    name: "LinkedIn",
    icon: <FaLinkedin size={25} />,
    link: "https://www.linkedin.com/company/mistri-builders",
  },
  {
    name: "Instagram",
    icon: <FaInstagram size={25} />,
    link: "https://www.instagram.com/mistri.builders/",
  },
];

const Footer = () => {
  // useEffect(() => {
  //   AOS.init();
  // }, []);

  const renderLinks = (title, items) => (
    <Box>
      <Typography
        sx={{
          fontWeight: "600",
          marginBottom: "10px",
        }}
        variant="h4"
      >
        {title}
      </Typography>
      <Box sx={{ mt: 3 }}>
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: items === socialMediaLinks && "center",
              flexDirection: items === socialMediaLinks ? "row" : "column",
              marginBottom: items === socialMediaLinks ? "0" : "15px",
            }}
          >
            {items === socialMediaLinks && (
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                color="inherit"
                aria-label={item.name}
              >
                {item.icon}
              </Box>
            )}
            {/* {item.isScroll ? (
              <HashLink to={item.link} style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    ml: items === socialMediaLinks ? 1 : 0,
                    fontSize: items === socialMediaLinks ? "16px" : "16px",
                    fontWeight: "400",
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "white",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {item.name}
                </Typography>
              </HashLink>
            ) : (
              <Link
                href={
                  item.link
                    ? item.link
                    : // : typeof item.name === "string"
                      // ? `/${item.name.toLowerCase()}`
                      "/"
                }
                // href={
                //   item.link
                //     ? item.link
                //     : typeof item.name === "string"
                //     ? item.name.startsWith("E-Mail:")
                //       ? `mailto:${item.name.split(":")[1].trim()}`
                //       : item.name.startsWith("Tel:")
                //       ? `tel:${item.name.split(":")[1].trim()}`
                //       : "/"
                //     : "/"
                // }
                sx={{
                  ml: items === socialMediaLinks ? 1 : 0,
                  fontSize: items === socialMediaLinks ? "16px" : "16px",
                  fontWeight: "400",
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "inherit",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                  whiteSpace: "pre-wrap",
                }}
                target={item.target ? "_blank" : ""}
              >
                {item.name}
              </Link>
            )} */}
            <Link
              href={
                item.link
                  ? item.link
                  : // : typeof item.name === "string"
                    // ? `/${item.name.toLowerCase()}`
                    "/"
              }
              // href={
              //   item.link
              //     ? item.link
              //     : typeof item.name === "string"
              //     ? item.name.startsWith("E-Mail:")
              //       ? `mailto:${item.name.split(":")[1].trim()}`
              //       : item.name.startsWith("Tel:")
              //       ? `tel:${item.name.split(":")[1].trim()}`
              //       : "/"
              //     : "/"
              // }
              sx={{
                ml: items === socialMediaLinks ? 1 : 0,
                fontSize: items === socialMediaLinks ? "16px" : "16px",
                fontWeight: "400",
                cursor: "pointer",
                textDecoration: "none",
                color: "inherit",
                "&:hover": {
                  textDecoration: "underline",
                },
                whiteSpace: "pre-wrap",
              }}
              target={item.target ? "_blank" : ""}
            >
              {/* {item.name.startsWith("E-Mail:") ||
                item.name.startsWith("Tel:") ||
                item.name.startsWith("Address:")
                  ? item.name.split(":")[1].trim()
                  : item.name} */}
              {item.name}
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <Box
      // data-aos="fade-up"
      sx={{
        paddingTop: { md: 10, xs: 5 },
        paddingBottom: { md: 5, xs: 3 },
        backgroundColor: "#AE4034",
        color: "#FFF",
        paddingX: { lg: 12, md: 8, sm: 4, xs: 2 },
      }}
    >
      <Container maxWidth="xl" sx={{ pl: "0 !important", pr: "0 !important" }}>
        <Grid container spacing={8} sx={{ marginBottom: "60px" }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignContent="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={2}
            >
              <Box
                sx={{
                  width: {
                    md: "300px",
                    sm: "240px",
                    xs: "180px",
                  },
                }}
              >
                <img src={MBLogo} alt="mb-logo" width="100%" height="100%" />
              </Box>
            </Box>
            <Stack
              direction="row"
              justifyContent="center"
              sx={{
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <Link
                href={process.env.REACT_APP_APP_STORE_LINK}
                target="_blank"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <img
                  src={BadgeiOS}
                  alt="ios"
                  style={{ width: "150px" }}
                  loading="lazy"
                />
              </Link>
              <Link
                href={process.env.REACT_APP_PLAY_STORE_LINK}
                target="_blank"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <img
                  src={BadgeAndroid}
                  alt="android"
                  style={{ width: "165px" }}
                  loading="lazy"
                />
              </Link>
            </Stack>
            {/* <Typography variant="body1" color="white" textAlign="center" mt>
              * Launching Soon...
            </Typography> */}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            {renderLinks("Need Help", [
              { name: "FAQ", link: "/faq" },
              { name: "Contact Us", link: "/contact" },
              {
                name: "E-Mail: relations@mistri.builders",
                link: "mailto:relations@mistri.builders",
              },
              {
                name: "Tel: +91 96096 91096",
                link: "tel:+919609691096",
              },
              {
                name: "181/23, Industrial Area,\nPhase 1, Chandigarh, \n160002",
                link: "https://maps.app.goo.gl/exK8E6BzHc48i89t9",
                target: true,
              },
            ])}
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            {renderLinks("About Us", [
              { name: "Our Story", link: "/about" },
              { name: "Benefits", link: "/#benefits", isScroll: true },
              { name: "Team", link: "/about#ourteam", isScroll: true },
              {
                name: "Partner With Us",
                link: "https://bit.ly/partner-with-mistri-builders",
              },
            ])}
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            {renderLinks("Follow Us", socialMediaLinks)}
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column-reverse" },
            justifyContent: "space-between",
            marginTop: 2,
            alignItems: "center",
            ml: {
              lg: 5,
              xs: 0,
            },
            mr: {
              lg: 10,
              xs: 0,
            },
          }}
        >
          <Box>
            <Typography
              variant="body1"
              sx={{
                my: {
                  md: 0,
                  xs: 4,
                },
                opacity: "0.5",
              }}
            >
              &copy; {new Date().getFullYear()} Mistri.Builders. All rights
              reserved.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: { md: "max-content", xs: "100%" },
              gap: { lg: 8, md: 6, sm: 4, xs: "10px" },
            }}
          >
            <Link
              target="_blank"
              href="/terms-and-conditions"
              sx={{
                fontSize: { md: "16px", xs: "14px" },
                fontWeight: "400",
                cursor: "pointer",
                color: "inherit",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Terms & Conditions
            </Link>
            <Link
              target="_blank"
              href="/refund-policy"
              sx={{
                fontSize: { md: "16px", xs: "14px" },
                fontWeight: "400",
                cursor: "pointer",
                color: "inherit",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Refund Policy
            </Link>
            <Link
              target="_blank"
              href="/privacy-policy"
              sx={{
                fontSize: { md: "16px", xs: "14px" },
                fontWeight: "400",
                cursor: "pointer",
                color: "inherit",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Privacy Policy
            </Link>
          </Box>
        </Box>
        {/* <Grid container spacing={8}>
          <Grid item md={6}>
            <Typography
              variant="body1"
              sx={{
                opacity: "0.5",
              }}
            >
              &copy; {new Date().getFullYear()}. Mistri.Builders. All rights
              reserved.
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Link
                  href="/terms-and-conditions"
                  sx={{
                    fontSize: { md: "16px", xs: "14px" },
                    fontWeight: "400",
                    cursor: "pointer",
                    color: "inherit",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Terms & Conditions
                </Link>
              </Grid>
              <Grid item xs={4}>
                <Link
                  href=""
                  sx={{
                    fontSize: { md: "16px", xs: "14px" },
                    fontWeight: "400",
                    cursor: "pointer",
                    color: "inherit",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Refund policy
                </Link>
              </Grid>
              <Grid item xs={4}>
                <Link
                  href="/privacy-policy"
                  sx={{
                    fontSize: { md: "16px", xs: "14px" },
                    fontWeight: "400",
                    cursor: "pointer",
                    color: "inherit",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Privacy Policy
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </Container>
    </Box>
  );
};

export default Footer;
