import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { retry } from "./common/utils/CommonFunction.js";
import { useAuthContext } from "./hooks/useAuthContext.jsx";
import Interior from "./Pages/Interior.jsx";
import MobileApk from "./Pages/MobileApk.jsx";

const Home = lazy(() => retry(() => import("../src/Pages/Home")));
const FourOhFour = lazy(() => retry(() => import("../src/Pages/FourOhFour")));
const About = lazy(() => retry(() => import("../src/Pages/About")));
// const Service = lazy(() => retry(() => import("../src/Pages/Service")));
const Contact = lazy(() => retry(() => import("../src/Pages/Contact")));
const Dashboard = lazy(() => retry(() => import("../src/Pages/Dashboard")));
const TermsAndConditions = lazy(() =>
  retry(() => import("../src/Pages/TermsAndConditions"))
);
const PrivacyPolicy = lazy(() =>
  retry(() => import("../src/Pages/PrivacyPolicy"))
);
const RefundPolicy = lazy(() =>
  retry(() => import("../src/Pages/RefundPolicy"))
);
const FaqPage = lazy(() => retry(() => import("../src/Pages/Faq1")));
const DeleteAccount = lazy(() =>
  retry(() => import("../src/Pages/DeleteAccount.jsx"))
);
const Support = lazy(() => retry(() => import("../src/Pages/Support.jsx")));

const Routes = () => {
  const { token } = useAuthContext();

  const routes = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/*",
      element: <Navigate to="/404" />,
    },
    {
      path: "/404",
      element: <FourOhFour />,
    },
    // {
    //   path: "/service",
    //   element: <Service />,
    // },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/interior",
      element: <Interior />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/Faq",
      element: <FaqPage />,
    },
    {
      path: "/terms-and-conditions",
      element: <TermsAndConditions />,
    },
    {
      path: "/refund-policy",
      element: <RefundPolicy />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/dashboard",
      element: token ? <Dashboard /> : <Navigate replace to="/" />,
    },
    {
      path: "/app-home",
      element: <Home />,
    },
    // {
    //   path: "/app-service",
    //   element: <Service />,
    // },
    {
      path: "/app-interior",
      element: <Interior />,
    },
    {
      path: "/app-dashboard",
      element: token ? <Dashboard /> : <Navigate replace to="/" />,
    },
    {
      path: "/mobile-apk",
      element: <MobileApk />,
    },
    {
      path: "/delete-account",
      element: <DeleteAccount />,
    },
    {
      path: "/support",
      element: <Support />,
    },
  ]);

  return routes;
};

export default Routes;
