import React from "react";
import { Helmet } from "react-helmet-async";
import OurProcess from "../components/Interior/Process";
import AboutUs from "../components/Interior/AboutUs";
import Story from "../components/Interior/Story";
import InteriorBanner from "../components/Interior/InteriorBanner";
import Design from "../components/Interior/Design";
import Testimonials from "../components/Interior/Testimonials";
import ImgCompare from "../components/Interior/ImgCompare";

const Interior = () => {
  const isAppPath = window.location.pathname === "/app-interior";

  return (
    <>
      <Helmet>
        <title>
          Architecture & Interior | {process.env.REACT_APP_APP_NAME}
        </title>
      </Helmet>
      <InteriorBanner />
      <Story />
      <Design />
      {!isAppPath && <AboutUs />}
      <OurProcess />
      <ImgCompare />
      {!isAppPath && <Testimonials />}
    </>
  );
};

export default Interior;
