import React, { useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";
import Raw from "../../resources/images/Banner/Raw.jpg";
import Render from "../../resources/images/Banner/Render.jpg";
import Real from "../../resources/images/Banner/real.jpg";
import handle from "../../resources/svgs/Handle.svg";
import { ImgComparisonSlider } from "@img-comparison-slider/react";

const ImgCompare = () => {
  useEffect(() => {
    const sliders = document.querySelectorAll(".rendered");
    sliders.forEach((slider) => {
      const root = slider.shadowRoot;
      if (root) {
        const firstDiv = root.querySelector("div#first.first.horizontal");
        if (firstDiv) {
          const handleContainer = firstDiv.querySelector(
            "div.handle-container"
          );
          if (handleContainer) {
            const handleSlot = handleContainer.querySelector(
              "div#handle.handle slot"
            );
            if (handleSlot) {
              handleSlot.innerHTML = "";
              const img = document.createElement("img");
              img.src = handle;
              img.alt = "";
              img.loading = "lazy";
              img.style.width = "55px";
              img.style.height = "55px";
              handleSlot.appendChild(img);
            }
          }
        }
      }
    });
  }, []);

  return (
    <Container maxWidth="xl" sx={{ pl: "0 !important", pr: "0 !important" }}>
      <Grid container spacing={{ xs: 2, md: 0 }}>
        <Grid item xs={12} md={6}>
          <Box position="relative" textAlign="center">
            <ImgComparisonSlider
              style={{
                background: "#E6E6E6",
                width: "100%",
              }}
            >
              <img slot="first" src={Raw} height="500px" width="100%" alt="" />
              <img
                slot="second"
                src={Render}
                height="500px"
                width="100%"
                alt=""
              />
            </ImgComparisonSlider>
            <Box
              bottom={14}
              bgcolor="rgba(0, 0, 0, 0.5)"
              color="#fff"
              p={1}
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                width: "max-content",
              }}
            >
              Raw v/s Render
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box position="relative" textAlign="center">
            <ImgComparisonSlider
              style={{
                background: "#E6E6E6",
                width: "100%",
              }}
            >
              <img
                slot="first"
                src={Render}
                height="500px"
                width="100%"
                alt=""
              />
              <img
                slot="second"
                src={Real}
                height="500px"
                width="100%"
                alt=""
              />
            </ImgComparisonSlider>
            <Box
              bottom={14}
              bgcolor="rgba(0, 0, 0, 0.5)"
              color="#fff"
              p={1}
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                width: "max-content",
              }}
            >
              Render v/s Real
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ImgCompare;
