// Import necessary modules
import React, { Suspense } from "react";
import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";
import { styled } from "@mui/material";
import { useLocation } from "react-router-dom";
import ThemeProvider from "./theme/index";
import Navbar from "./components/common/Navbar";
import Loader from "./components/common/Loader";
import ScrollToTop from "./components/common/ScrollToTop";
import Footer from "./components/common/Footer";
import Routes from "./Routes";
import ScheduleButton from "./components/common/ScheduleButton";

// StyledSnackbarProvider with styles for notistack
const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.notistack-MuiContent-success {
    background-color: #ae4034;
  }
  ,
  &.notistack-MuiContent {
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
      0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

function App() {
  const location = useLocation();
  const pathsToHideNavbarAndFooter = [
    "/404",
    "/app-home",
    "/app-service",
    "/app-dashboard",
    "/app-interior",
    "/mobile-apk",
    "/terms-and-conditions",
    "/refund-policy",
    "/privacy-policy",
    "/faq",
    "/delete-account",
    "/support",
  ];
  const shouldHideNavbarAndFooter = pathsToHideNavbarAndFooter.includes(
    location.pathname
  );

  const isSpecialPage =
    location.pathname === "/404" ||
    location.pathname === "/app-home" ||
    location.pathname === "/app-service" ||
    location.pathname === "/app-interior" ||
    location.pathname === "/app-dashboard" ||
    location.pathname === "/mobile-apk" ||
    location.pathname === "/terms-and-conditions" ||
    location.pathname === "/refund-policy" ||
    location.pathname === "/faq" ||
    location.pathname === "/privacy-policy" ||
    location.pathname === "/support" ||
    location.pathname === "/delete-account";

  return (
    <StyledSnackbarProvider
      hideIconVariant
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <HelmetProvider>
        <ThemeProvider>
          {!isSpecialPage && <ScheduleButton />}
          {!shouldHideNavbarAndFooter && <Navbar />}
          <ScrollToTop />
          <Suspense fallback={<Loader />}>
            <Routes />
          </Suspense>
          {!shouldHideNavbarAndFooter && <Footer />}
        </ThemeProvider>
      </HelmetProvider>
    </StyledSnackbarProvider>
  );
}

export default App;
