import React, { useEffect } from "react";
import { Container, Box, Typography, IconButton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { LuInstagram, LuMail } from "react-icons/lu";
import { FaLinkedinIn } from "react-icons/fa";
import "swiper/css";
import "swiper/css/pagination";
// import AOS from "aos";
// import "aos/dist/aos.css";

const TestimonialSlide = ({ text, author, position }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    textAlign="center"
    maxWidth="800px"
    // data-aos="fade-up"
  >
    <Typography variant="body1" my={2}>
      {text}
    </Typography>
    <Typography variant="h6" fontWeight="700">
      {author}
    </Typography>
    <Typography variant="body1">{position}</Typography>
  </Box>
);

const SocialMediaIcons = () => (
  <Box display="flex" alignItems="center" gap={2.5}>
    <a
      href="https://www.instagram.com/bokettodesigns/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconButton
        sx={{
          color: "#FFF",
          backgroundColor: "#AE4034",
          "&:hover": {
            backgroundColor: "#343434",
          },
        }}
      >
        <LuInstagram size={18} />
      </IconButton>
    </a>
    <a
      href="https://in.linkedin.com/company/boketto-designs"
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconButton
        sx={{
          color: "#FFF",
          backgroundColor: "#AE4034",
          "&:hover": {
            backgroundColor: "#343434",
          },
        }}
      >
        <FaLinkedinIn size={18} />
      </IconButton>
    </a>
    <a href="mailto:info@bokettodesigns.in">
      <IconButton
        sx={{
          color: "#FFF",
          backgroundColor: "#AE4034",
          "&:hover": {
            backgroundColor: "#343434",
          },
        }}
      >
        <LuMail size={18} />
      </IconButton>
    </a>
  </Box>
);

const Testimonials = () => {
  // useEffect(() => {
  //   AOS.init();
  // }, []);

  return (
    <Container
      maxWidth="xl"
      sx={{ pl: "0 !important", pr: "0 !important", my: 10 }}
      // data-aos="fade-up"
    >
      <Typography variant="h1" textAlign="center" fontWeight="800">
        Testimonials
      </Typography>
      <Box sx={{ margin: "auto", width: { md: "50%", sm: "80%", xs: "100%" } }}>
        <Swiper
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
        >
          <SwiperSlide>
            <TestimonialSlide
              text='"Working with Sakshi Gupta was one of the best teams I have worked with. She was very easy to approach, understood everything, and paid close attention to delivering her finest work. With the help of her creative and hardworking team, I wish her the best of luck for all her upcoming ventures and hope to work with her team in many of our upcoming projects."'
              author="Chef Lalit Tuli"
              position="Managing Director, Tandoori Nights"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialSlide
              text='"Working with Sakshi and the Boketto Team was an absolute pleasure! Their keen eye for detail transformed our restaurant into a stunning space.They took my vague ideas and turned them into something straight out of a design"'
              author="Akash Arora"
              position="Restaurant Owner"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialSlide
              text='"Year 2020 … Sakshi Shaifali .. two young girls had just started their independent venture- ‘Boketto’when Covidstruck. But these resilient girls marched on regardless!I got glowing feedback about them from my friend and Idecided to go ahead with them.The Boketto team did an amazing job at my place and made my house a ‘home’! Thorough,sincere, aesthetic and particular about deadlines! That’s Team Boketto!!"'
              author="Anupama Mehtani"
              position="Client"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialSlide
              text='"We are so grateful to have chosen Sakshi and her team, Boketto Designs, for the renovation of our house. Sakshi and her team combined my ideas and vision with their expertise and experience to create a beautifully designed space. They considered not only what the space was going to look like but also how it was going to be used. I am extremely happy with the services provided by Sakshi and her team at Boketto Designs and with the way our space turned out."'
              author="Alok Mehta"
              position="Proprietor, NMC Engineers"
            />
          </SwiperSlide>
        </Swiper>
      </Box>

      <Box
        sx={{
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" my={1.5}>
          Follow Boketto Designs
        </Typography>
        <SocialMediaIcons />
        <Typography my={1.5}>
          Copyright © Boketto Designs. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default Testimonials;
