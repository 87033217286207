import React, { useState, forwardRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Slide,
  Dialog,
  DialogContent,
  IconButton,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { LuX } from "react-icons/lu";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useLogout } from "../../hooks/useLogout";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangePassword = ({ changePassword, setChangePassword }) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useLogout();

  const passwordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confPassword: "",
    },
    validationSchema: yup.object({
      oldPassword: yup
        .string("* Enter old password")
        .min(8, "* Password should be of minimum 8 characters length")
        .required("* Old password is required"),
      password: yup
        .string("* Enter new password")
        .min(8, "* Password should be of minimum 8 characters length")
        .required("* New password is required"),
      confPassword: yup
        .string("* Enter confirm password")
        .min(8, "* Password should be of minimum 8 characters length")
        .oneOf(
          [yup.ref("password"), null],
          "* Confirm password must be same as password"
        )
        .required("* Confirm password is required"),
    }),
    onSubmit: async (values) => {
      setError();
      setIsLoading(true);

      const config = {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Api-Key": process.env.REACT_APP_API_KEY,
        },
      };

      const body = {
        oldPassword: values.oldPassword,
        password: values.password,
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/users/change-password`,
          body,
          config
        )
        .then((response) => {
          console.log(response);
          enqueueSnackbar(response.data.message, {
            variant: "success",
          });
          setError();
          setIsLoading(false);
          passwordFormik.resetForm();
          setChangePassword(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error.response.data.message);
          setIsLoading(false);
          if (error?.response?.data?.statusCode === 401) {
            logout();
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
          }
        });
    },
  });

  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClose = () => {
    setChangePassword(false);
    passwordFormik.resetForm();
  };

  return (
    <Dialog
      sx={{
        transition: "1.5s ease",
      }}
      open={changePassword}
      TransitionComponent={Transition}
      onClose={() => setChangePassword(false)}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent
        sx={{
          backgroundColor: "#FAF9F6 !important",
          color: "#000000 !important",
          borderRadius: "36px",
          backdropFilter: "blur(15px)",
          outline: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={handleClose} sx={{ color: "#000" }}>
            <LuX />
          </IconButton>
        </Box>
        <Typography variant="h3" mb={4} textAlign="center">
          Change Your Password!
        </Typography>
        <Box>
          <form onSubmit={passwordFormik.handleSubmit}>
            {error && (
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: "#EFD9D6",
                  color: "primary.main",
                  borderRadius: "10px",
                  p: 2,
                  mb: 2,
                }}
              >
                {error}
              </Typography>
            )}
            <Box my={1.5}>
              <TextField
                autoComplete="username email"
                sx={{ mt: 0.5 }}
                type={showOldPassword ? "text" : "password"}
                fullWidth
                variant="outlined"
                id="oldPassword"
                name="oldPassword"
                label="Old Password"
                value={passwordFormik.values.oldPassword}
                onChange={passwordFormik.handleChange}
                error={
                  passwordFormik.touched.oldPassword &&
                  Boolean(passwordFormik.errors.oldPassword)
                }
                helperText={
                  passwordFormik.touched.oldPassword &&
                  passwordFormik.errors.oldPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowOldPassword()}
                      >
                        {showOldPassword ? (
                          <FiEye color="#130F26" />
                        ) : (
                          <FiEyeOff color="#130F26" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box my={1.5}>
              <TextField
                sx={{ mt: 0.5 }}
                type={showNewPassword ? "text" : "password"}
                fullWidth
                variant="outlined"
                id="password"
                name="password"
                label="New Password"
                value={passwordFormik.values.password}
                onChange={passwordFormik.handleChange}
                error={
                  passwordFormik.touched.password &&
                  Boolean(passwordFormik.errors.password)
                }
                helperText={
                  passwordFormik.touched.password &&
                  passwordFormik.errors.password
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowNewPassword()}
                      >
                        {showNewPassword ? (
                          <FiEye color="#130F26" />
                        ) : (
                          <FiEyeOff color="#130F26" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box my={1.5}>
              <TextField
                sx={{ mt: 0.5 }}
                type={showNewPassword ? "text" : "password"}
                fullWidth
                variant="outlined"
                id="confPassword"
                name="confPassword"
                label="Confirm Password"
                value={passwordFormik.values.confPassword}
                onChange={passwordFormik.handleChange}
                error={
                  passwordFormik.touched.confPassword &&
                  Boolean(passwordFormik.errors.confPassword)
                }
                helperText={
                  passwordFormik.touched.confPassword &&
                  passwordFormik.errors.confPassword
                }
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "white" }} />
                }
                sx={{ my: 2, px: 8 }}
              >
                Change Password
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePassword;
